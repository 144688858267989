@import url("https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.cdnfonts.com/css/apercu-pro");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  margin: 0 auto;
  padding: 0rem 0rem;
  --font-grotesk: "Hanken Grotesk", sans-serif;
  font-family: var(--font-grotesk);
  overflow-x: hidden;
  /* font-family: "Apercu Pro", sans-serif; */
}

.blinking-button {
  animation: blink 1s linear infinite;
}

@keyframes blink {
  0%,
  100% {
    box-shadow: 5px 5px 5px #ffffff;
  }
  50% {
    box-shadow: 7px 7px 7px #f28182;
  }
}

.home {
  background-image: url("./Assets/Images/HomePage/home_background_image.svg");
  background-repeat: no-repeat;
  background-position: top;
  background-attachment: fixed;
}

.app {
  @apply z-[9] flex justify-center items-center flex-col w-[22rem] min-[320px]:w-[22rem] min-[375px]:w-[24rem] sm:w-[30rem] min-[650px]:w-[45rem] md:w-[48rem] lg:w-[60rem] xl:w-[60rem] 2xl:w-[68rem] mx-auto sm:px-6 px-2;
}

.flex-center {
  @apply flex justify-center items-center;
}

.flex-start {
  @apply flex justify-start items-start;
}

.flex-end {
  @apply flex justify-end items-center;
}

.flex-between {
  @apply flex justify-between items-center;
}

.h1 {
  @apply font-bold text-[2.5rem] leading-[3.25rem] md:text-[2.75rem] md:leading-[3.75rem] lg:text-[rem] lg:leading-[4.0625rem] xl:text-[3.25rem] xl:leading-[4.7rem];
}

.h2 {
  @apply text-[1.5rem] leading-[2.5rem] md:text-[1.8rem] md:leading-[2.5rem] lg:text-[2rem] lg:leading-[3.5rem] xl:text-[2.5rem] xl:leading-[4rem];
}

.h3 {
  @apply text-[2rem] leading-normal md:text-[2.5rem];
}

.h4 {
  @apply text-[2rem] leading-normal;
}

.h5 {
  @apply text-2xl leading-normal;
}

.h6 {
  @apply font-bold text-xl leading-8;
}

.h7 {
  @apply font-bold text-lg leading-8;
}

.h8 {
  @apply font-bold text-[1.1rem] leading-6;
}

.body-1 {
  @apply text-[0.875rem] leading-[1.5rem] md:text-[1rem] md:leading-[1.75rem] lg:text-[1.25rem] lg:leading-8;
}

.body-2 {
  @apply font-medium text-[1rem] leading-6;
}

.body-3 {
  @apply font-medium text-[0.9rem] leading-6;
}

.body-4 {
  @apply font-medium text-[0.8rem] leading-6;
}

.body-5 {
  @apply font-medium text-[0.7rem] leading-6;
}
