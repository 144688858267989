@media (min-width: 0px) {
  html {
    font-size: 10px;
  }
}

@media (min-width: 320px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 375px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 640px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 1024px) {
  html {
    font-size: 18px;
  }
}

@media (min-width: 1280px) {
  html {
    font-size: 20px;
  }
}

/* 2xl */
@media (min-width: 1536px) {
  html {
    font-size: 22px;
  }
}

/* 3xl */
@media (min-width: 1792px) {
  html {
    font-size: 24px;
  }
}

/* 4xl */
@media (min-width: 2048px) {
  html {
    font-size: 26px;
  }
}

@media (min-width: 2304px) {
  html {
    font-size: 28px;
  }
}

@media (min-width: 2560px) {
  html {
    font-size: 30px;
  }
}

@media (min-width: 2816px) {
  html {
    font-size: 32px;
  }
}

@media (min-width: 3072) {
  html {
    font-size: 34px;
  }
}

@media (min-width: 3328) {
  html {
    font-size: 36px;
  }
}

@media (min-width: 3584px) {
  html {
    font-size: 38px;
  }
}
